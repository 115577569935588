import React from 'react';
import './footer.css';

// Footer functional component
const Footer = () => {
    return (
        <footer className="custom-footer">
            {/* Social media logos with links */}
            <div className="footer-logos">
                {/* Facebook Logo with link */}
                <a href="https://www.facebook.com/people/Mac-Party-Productions/100084974506729/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M15 2.55005C8.125 2.55005 2.5 8.16255 2.5 15.075C2.5 21.325 7.075 26.5125 13.05 27.45V18.7H9.875V15.075H13.05V12.3125C13.05 9.17505 14.9125 7.45005 17.775 7.45005C19.1375 7.45005 20.5625 7.68755 20.5625 7.68755V10.775H18.9875C17.4375 10.775 16.95 11.7375 16.95 12.725V15.075H20.425L19.8625 18.7H16.95V27.45C19.8956 26.9849 22.5778 25.4819 24.5124 23.2126C26.4471 20.9433 27.5067 18.0571 27.5 15.075C27.5 8.16255 21.875 2.55005 15 2.55005Z" fill="white" />
                    </svg>
                </a>
                {/* Instagram Logo with link */}
                <a href="https://www.instagram.com/macspartyproductions/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M9.75 2.5H20.25C24.25 2.5 27.5 5.75 27.5 9.75V20.25C27.5 22.1728 26.7362 24.0169 25.3765 25.3765C24.0169 26.7362 22.1728 27.5 20.25 27.5H9.75C5.75 27.5 2.5 24.25 2.5 20.25V9.75C2.5 7.82718 3.26384 5.98311 4.62348 4.62348C5.98311 3.26384 7.82718 2.5 9.75 2.5ZM9.5 5C8.30653 5 7.16193 5.47411 6.31802 6.31802C5.47411 7.16193 5 8.30653 5 9.5V20.5C5 22.9875 7.0125 25 9.5 25H20.5C21.6935 25 22.8381 24.5259 23.682 23.682C24.5259 22.8381 25 21.6935 25 20.5V9.5C25 7.0125 22.9875 5 20.5 5H9.5ZM21.5625 6.875C21.9769 6.875 22.3743 7.03962 22.6674 7.33265C22.9604 7.62567 23.125 8.0231 23.125 8.4375C23.125 8.8519 22.9604 9.24933 22.6674 9.54235C22.3743 9.83538 21.9769 10 21.5625 10C21.1481 10 20.7507 9.83538 20.4576 9.54235C20.1646 9.24933 20 8.8519 20 8.4375C20 8.0231 20.1646 7.62567 20.4576 7.33265C20.7507 7.03962 21.1481 6.875 21.5625 6.875ZM15 8.75C16.6576 8.75 18.2473 9.40848 19.4194 10.5806C20.5915 11.7527 21.25 13.3424 21.25 15C21.25 16.6576 20.5915 18.2473 19.4194 19.4194C18.2473 20.5915 16.6576 21.25 15 21.25C13.3424 21.25 11.7527 20.5915 10.5806 19.4194C9.40848 18.2473 8.75 16.6576 8.75 15C8.75 13.3424 9.40848 11.7527 10.5806 10.5806C11.7527 9.40848 13.3424 8.75 15 8.75ZM15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15C11.25 15.9946 11.6451 16.9484 12.3483 17.6517C13.0516 18.3549 14.0054 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6517 17.6517C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.0054 18.3549 13.0516 17.6517 12.3483C16.9484 11.6451 15.9946 11.25 15 11.25Z" fill="white" />
                    </svg>
                </a>
                {/* TikTok Logo with link */}
                {/* <a href="https://www.tiktok.com/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M21.25 10.7V9.2C20.0666 9.2 18.919 8.8207 17.9998 8.12228C17.0806 7.42387 16.4453 6.44903 16.2 5.35V5.3375V3.75H13.0875V19.375C13.0875 20.5788 12.6111 21.7329 11.7648 22.5792C10.9185 23.4255 9.76435 23.9019 8.56053 23.9019C7.3567 23.9019 6.20255 23.4255 5.35626 22.5792C4.50997 21.7329 4.03356 20.5788 4.03356 19.375C4.03356 18.1712 4.50997 17.0171 5.35626 16.1708C6.20255 15.3245 7.3567 14.8481 8.56053 14.8481C9.02553 14.8481 9.47553 14.9231 9.89553 15.0606V11.8481C9.45809 11.783 9.01646 11.7505 8.57428 11.7506C6.46107 11.7506 4.43448 12.5796 2.93856 14.0756C1.44264 15.5715 0.613647 17.5981 0.613647 19.7113C0.613647 21.8245 1.44264 23.8511 2.93856 25.347C4.43448 26.8429 6.46107 27.6719 8.57428 27.6719C10.6875 27.6719 12.7141 26.8429 14.21 25.347C15.7059 23.8511 16.5349 21.8245 16.5349 19.7113V11.5356C17.933 12.5743 19.5736 13.1381 21.25 13.1625V10.7Z" fill="white" />
                    </svg>
                </a> */}
            </div>
            {/* Footer text content */}
            <div className="footer-text">
                <p>Copyright © {new Date().getFullYear()} Mac Party Productions. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
