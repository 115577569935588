import React, { useState } from 'react';
import './additional_service.css';
import photobooth from '../images/photobooth.png';

// The AdditionalService functional component
function AdditionalService() {
    const [showModal, setShowModal] = useState(false);

    return (
        <section id="additional-services" className="additional-services">
            <div className="container">
                <h2>Additional Services</h2>

                {/* Santa Service */}
                <div className="text-container">
                    <div className="service-item">
                        <h3>Seasonal Santa Service</h3>
                        <p>During the festive months of November and December, add a sprinkle of magic to your events with our exclusive Santa service. Perfect for holiday parties, corporate events, and special engagements.</p>
                    </div>
                </div>


                {/* Photo Booth */}
                <div className="text-container">
                    <div className="service-item">
                        <h3>Digital Photo Booth Rental</h3>
                        <p>Make your event memorable with our state-of-the-art Digital Photo Booth!</p>
                        <div className="pricing">
                            <h4>$375 for 4 Hours</h4>
                        </div>
                        <ul>
                            <li>Professional Setup</li>
                            <li>Instant Digital Photos</li>
                            <li>Fun Digital Props and Filters</li>
                            <li>Unlimited Photos During Event</li>
                        </ul>
                        <button onClick={() => setShowModal(true)} className="view-booth-btn">
                            Click here to see the Photo Booth
                        </button>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal-overlay" onClick={() => setShowModal(false)}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <img src={photobooth} alt="Digital booth display" />
                        <button className="modal-close" onClick={() => setShowModal(false)}>×</button>
                    </div>
                </div>
            )}
        </section>
    );
}

export default AdditionalService;
