import { useState, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import './AdminPage.css';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const AdminPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [uploading, setUploading] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [currentUpload, setCurrentUpload] = useState(null);
    const [storageStats, setStorageStats] = useState({ used: 0, limit: 0 });
    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes
    const [fileError, setFileError] = useState('');

    useEffect(() => {
        const authStatus = localStorage.getItem('isAdminAuthenticated');
        if (authStatus === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchImages();
            fetchStorageStats();
        }
    }, [isAuthenticated]);

    const fetchImages = async () => {
        try {
            const { data: files, error } = await supabase
                .storage
                .from('gallery-images')
                .list();

            if (error) {
                console.error('Error fetching images:', error);
                return;
            }

            const imageUrls = files.map(file => ({
                name: file.name,
                url: `${supabaseUrl}/storage/v1/object/public/gallery-images/${file.name}`
            }));

            setImages(imageUrls);
        } catch (err) {
            console.error('Error:', err);
        }
    };

    const fetchStorageStats = async () => {
        try {
            const { data: files, error } = await supabase
                .storage
                .from('gallery-images')
                .list('', {
                    limit: 100,
                    offset: 0,
                    sortBy: { column: 'name', order: 'asc' }
                });

            if (error) throw error;

            // Calculate total size of all files in bytes
            const totalSize = files.reduce((acc, file) => acc + (file.metadata?.size || 0), 0);

            // Convert to MB with 2 decimal places
            const usedSizeMB = (totalSize / (1024 * 1024)).toFixed(2);
            const storageLimitMB = 500; // 500MB storage limit

            setStorageStats({
                used: Number(usedSizeMB),
                limit: storageLimitMB
            });

            // Update storage bar appearance based on usage
            const usagePercent = (usedSizeMB / storageLimitMB) * 100;
            const storageBar = document.querySelector('.storage-used');
            if (storageBar) {
                storageBar.classList.remove('warning', 'critical');
                if (usagePercent > 90) {
                    storageBar.classList.add('critical');
                } else if (usagePercent > 70) {
                    storageBar.classList.add('warning');
                }
            }

            console.log(`Storage stats: ${usedSizeMB}MB used of ${storageLimitMB}MB`);
        } catch (err) {
            console.error('Error fetching storage stats:', err);
        }
    };

    const handleDelete = async (imageName) => {
        if (window.confirm('Are you sure you want to delete this image?')) {
            try {
                const { error } = await supabase
                    .storage
                    .from('gallery-images')
                    .remove([imageName]);

                if (error) throw error;

                // Refresh images list
                fetchImages();
                alert('Image deleted successfully!');
            } catch (error) {
                console.error('Error deleting:', error);
                alert('Error deleting image');
            }
        }
    };

    const hashPassword = async (password) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const hashedPassword = await hashPassword(password);
        const correctHash = process.env.REACT_APP_ADMIN_PASSWORD_HASH;

        if (hashedPassword === correctHash) {
            setIsAuthenticated(true);
            localStorage.setItem('isAdminAuthenticated', 'true');
        } else {
            alert('Invalid password');
        }
    };

    const validateFile = useCallback((file) => {
        if (file.size > MAX_FILE_SIZE) {
            return `File "${file.name}" exceeds 50MB limit`;
        }
        return null;
    }, [MAX_FILE_SIZE]);

    const handleFileUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFileError('');

        // Validate each file
        const errors = selectedFiles
            .map(validateFile)
            .filter(error => error !== null);

        if (errors.length > 0) {
            setFileError(errors.join('\n'));
            setFiles([]);
            return;
        }

        setFiles(selectedFiles);
    };

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        setFileError('');

        const droppedFiles = Array.from(e.dataTransfer.files).filter(
            file => file.type.startsWith('image/')
        );

        // Validate dropped files
        const errors = droppedFiles
            .map(validateFile)
            .filter(error => error !== null);

        if (errors.length > 0) {
            setFileError(errors.join('\n'));
            setFiles([]);
            return;
        }

        setFiles(droppedFiles);
    }, [validateFile]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (files.length === 0) return;

        try {
            setUploading(true);

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                setCurrentUpload(`Uploading ${i + 1} of ${files.length}: ${file.name}`);
                setUploadProgress(0);

                const fileExt = file.name.split('.').pop();
                const fileName = `${Date.now()}_${i}.${fileExt}`;

                const { error: uploadError } = await supabase.storage
                    .from('gallery-images')
                    .upload(fileName, file, {
                        cacheControl: '3600',
                        upsert: true,
                        onUploadProgress: (progress) => {
                            const percent = (progress.loaded / progress.total) * 100;
                            setUploadProgress(percent);
                        }
                    });

                if (uploadError) throw uploadError;
            }

            alert('All files uploaded successfully!');
            setFiles([]);
            setUploadProgress(0);
            setCurrentUpload(null);
            fetchImages();
            fetchStorageStats(); // Refresh stats after upload
        } catch (error) {
            console.error('Error:', error);
            alert(`Upload error: ${error.message}`);
        } finally {
            setUploading(false);
        }
    };

    const handleSignOut = () => {
        setIsAuthenticated(false);
        setPassword('');
        setSelectedImages([]);
        localStorage.removeItem('isAdminAuthenticated');
    };

    const handleCheckboxChange = (imageName) => {
        setSelectedImages(prev =>
            prev.includes(imageName)
                ? prev.filter(name => name !== imageName)
                : [...prev, imageName]
        );
    };

    const handleDeleteSelected = async () => {
        if (selectedImages.length === 0) {
            alert('Please select images to delete');
            return;
        }

        if (window.confirm(`Are you sure you want to delete ${selectedImages.length} images?`)) {
            try {
                const results = await Promise.all(
                    selectedImages.map(imageName =>
                        supabase.storage
                            .from('gallery-images')
                            .remove([imageName])
                    )
                );

                const errors = results.filter(result => result.error);
                if (errors.length > 0) {
                    throw new Error('Some images failed to delete');
                }

                setSelectedImages([]);
                fetchImages();
                alert('Selected images deleted successfully!');
            } catch (error) {
                console.error('Error deleting:', error);
                alert('Error deleting images');
            }
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="login-box">
                <h2>Admin Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="user-box">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <label>Password</label>
                    </div>
                    <button type="submit">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Login
                    </button>
                </form>
            </div>
        );
    }

    return (
        <div className="admin-panel">
            <div className="admin-header">
                <h2>Admin Page</h2>
                <div className="storage-info">
                    <div className="storage-bar">
                        <div
                            className="storage-used"
                            style={{ width: `${(storageStats.used / storageStats.limit) * 100}%` }}
                        />
                    </div>
                    <p>Storage: {storageStats.used}MB / {storageStats.limit}MB</p>
                </div>
                <div className="signout-container">
                    <button onClick={handleSignOut} className="signout-btn">Sign Out</button>
                </div>
            </div>

            <form onSubmit={handleSubmit}
                onDragEnter={handleDrag}
                className="upload-form">
                <div
                    className={`drop-zone ${dragActive ? "drag-active" : ""}`}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileUpload}
                        className="file-input"
                        multiple
                    />
                    <div className="drop-zone-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
                        </svg>
                        <p>Drag files here or click to upload</p>
                        <p className="file-limit-info">Maximum file size: 50MB</p>
                        {fileError && <p className="file-error">{fileError}</p>}
                        {files.length > 0 && (
                            <p className="selected-file">
                                Selected: {files.length} file{files.length !== 1 ? 's' : ''}
                            </p>
                        )}
                    </div>
                </div>

                {currentUpload && (
                    <p className="upload-status">{currentUpload}</p>
                )}

                <div className={`progress-container ${uploadProgress > 0 ? 'visible' : ''}`}>
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{ width: `${uploadProgress}%` }}
                        />
                    </div>
                    <div
                        className="progress-percentage"
                        style={{ left: `${uploadProgress}%` }}
                    >
                        {Math.round(uploadProgress)}%
                    </div>
                </div>

                <button
                    type="submit"
                    className={`upload-button ${files.length > 0 ? 'active' : ''}`}
                    disabled={files.length === 0 || uploading}
                >
                    {uploading ? 'Uploading...' : 'Upload'}
                </button>
            </form>

            {selectedImages.length > 0 && (
                <button
                    onClick={handleDeleteSelected}
                    className="delete-selected-btn"
                >
                    Delete Selected ({selectedImages.length})
                </button>
            )}

            <div className="image-grid">
                {images.map((image) => (
                    <div key={image.name} className="image-item">
                        <input
                            type="checkbox"
                            checked={selectedImages.includes(image.name)}
                            onChange={() => handleCheckboxChange(image.name)}
                            className="image-checkbox"
                        />
                        <img src={image.url} alt={image.name} />
                        <button onClick={() => handleDelete(image.name)}>Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminPage;
