import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/navbar';
import Home from './components/home';
import Package from './components/package';
import Contact from './components/contact';
import Gallery from './components/gallery';
import Footer from './components/footer';
import AdditionalService from './components/additional_service';
import AdminPage from './components/AdminPage';
// Import any other required components here

// Add error boundary
class ErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('React Error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong. Please refresh the page.</h1>;
        }
        return this.props.children;
    }
}

// Create a MainContent component that includes all sections
const MainContent = () => {
    return (
        <>
            <div id="home"><Home /></div>
            <div id="gallery"><Gallery /></div>
            <div id="package"><Package /></div>
            <div id="additional-service"><AdditionalService /></div>
            <div id="contact"><Contact /></div>
        </>
    );
};

// Main App component which assembles all the page sections
function App() {
    console.log('App rendering...'); // Add logging
    return (
        <ErrorBoundary>
            <Router>
                <div className="App">
                    {/* Navigation Bar */}
                    <NavBar />
                    <Routes>
                        <Route path="/" element={<MainContent />} />
                        <Route path="/admin" element={<AdminPage />} />
                    </Routes>
                    {/* Footer */}
                    <Footer />
                </div>
            </Router>
        </ErrorBoundary>
    );
}

export default App;
