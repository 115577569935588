import { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { createClient } from '@supabase/supabase-js';
import "./gallery.css";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const Carousel = () => {
  const [currentImage, setCurrentImage] = useState(null);
  const [allImages, setAllImages] = useState([]);
  const [usedImages, setUsedImages] = useState(new Set());

  // Simple slide animation variant
  const slideVariants = {
    enter: { x: 1000, opacity: 0 },
    center: { x: 0, opacity: 1 },
    exit: { x: -1000, opacity: 0 }
  };

  // Get next random image that hasn't been used recently
  const getNextImage = useCallback(() => {
    if (allImages.length === 0) return null;

    // If we've used all images, reset the used images set
    if (usedImages.size >= allImages.length) {
      setUsedImages(new Set());
    }

    // Get available images that haven't been used
    const availableImages = allImages.filter(img => !usedImages.has(img));
    const randomIndex = Math.floor(Math.random() * availableImages.length);
    const nextImage = availableImages[randomIndex];

    // Add the chosen image to used set
    setUsedImages(prev => new Set([...prev, nextImage]));
    return nextImage;
  }, [allImages, usedImages]);

  // Fetch images once on mount
  useEffect(() => {
    async function fetchImages() {
      try {
        const { data: files, error } = await supabase
          .storage
          .from('gallery-images')
          .list();

        if (error || !files) {
          console.error('Error fetching images:', error);
          return;
        }

        const imageUrls = files.map(file =>
          `${supabaseUrl}/storage/v1/object/public/gallery-images/${file.name}`
        );

        setAllImages(imageUrls);
        // Set initial image
        if (imageUrls.length > 0) {
          const firstImage = imageUrls[Math.floor(Math.random() * imageUrls.length)];
          setCurrentImage(firstImage);
          setUsedImages(new Set([firstImage]));
        }
      } catch (err) {
        console.error('Error in fetchImages:', err);
      }
    }

    fetchImages();
  }, []);

  // Effect for changing images with timing
  useEffect(() => {
    if (allImages.length === 0) return;

    const interval = setInterval(() => {
      const nextImage = getNextImage();
      if (nextImage) {
        setCurrentImage(nextImage);
      }
    }, 5000); // 5 seconds total (3s display + 2s transition)

    return () => clearInterval(interval);
  }, [allImages, getNextImage]);

  return (
    <div className="carousel">
      <div className="carousel-images">
        <AnimatePresence mode="wait">
          {currentImage && (
            <motion.img
              key={currentImage}
              src={currentImage}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.5 }
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Carousel;
